"use strict";

export function initInScope($scope) {
    let $dropdowns = $scope.find('.js-keep-open-dropdown-menu');

    $dropdowns.on('click', function (evt) {
        evt.stopPropagation();
    });

}

