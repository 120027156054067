'use strict';

export function initInScope($scope) {

    // Expand direct input textarea on click.
    $scope.find('.js-quick-order').each(function () {
        let $container = $(this),
            $toggle = $container.find('.js-quick-order__toggle');

        $toggle.on('click', function () {
            $container.addClass('is-expand');
        });
    });

    // Reset quick order modal.
    let $csvTarget = $($scope.find('.js-quick-order__open').data('target'));
    $csvTarget.on('show.bs.modal', function () {
        $csvTarget.find('.js-quick-order__default').attr('hidden', false);
        $csvTarget.find('.js-quick-order__result').html(null);
    })

    // Unnecessary?
    $scope.find('.js-quick-order-focus').on('click', function () {
       let $target = $($(this).data('target'));

       $target.trigger('click');

       if ($(this).closest('.modal')) {
           let t = setTimeout(() => {
               $target.focus();
               clearTimeout(t);
           }, 400);
       }
    });

    let $form = $scope.find('.js-cart-detail__form');
    let $uploadCsvResultModal = $scope.find('.js-quick-order__result-modal');

    // Update cart when closing result modal.
    $uploadCsvResultModal.on("hidden.bs.modal", function () {
        if ($form.length > 0)
            $form.trigger('cart-update');
    });

    // Show result modal when quick order request is done.
    $form.on("quick-order-complete", function () {
        $uploadCsvResultModal.modal('show');
    });

    // Logic for direct input.
    $form.on("click", ".js-quick-order__direct-input-submit", function () {

        let $quickOrderDirectInput = $scope.find('.js-quick-order__direct-input');
        let $quickOrderDirectInputSubmit = $scope.find('.js-quick-order__direct-input-submit');

        let data = new FormData();
        data.append('quickOrderDirectContent', $quickOrderDirectInput.val());

        // Perform request, then show result modal.
        processQuickOrder($quickOrderDirectInputSubmit.data('action'), data, $scope).then(res => {
            $form.trigger('quick-order-complete');
        });
    });

    // Logic for csv upload.
    $scope.find('.js-quick-order__upload-modal').each(function () {
        let $this = $(this);
        let $quickOrderUploadForm = $this.find('.js-quick-order__upload-form');
        let $quickOrderUploadInput = $this.find('.js-quick-order__upload-input');

        // Submit when selecting file.
        $quickOrderUploadInput.on('change', function () {
            if ($(this).val()) {
                $quickOrderUploadForm.submit();
            }
        });

        $quickOrderUploadForm.on('submit', function (evt) {
            evt.preventDefault();

            let data = new FormData();
            data.append('quickOrderUploadContent', $quickOrderUploadInput[0].files[0]);

            // Perform request, then show result modal.
            processQuickOrder($quickOrderUploadForm.data('action'), data, $scope).then(res => {
                $uploadCsvResultModal.modal('show');
            });
        });
    });
}

/**
 * Performs quick order request, fills result modal and shows it.
 *
 * @param action
 * @param data
 * @param $scope
 * @returns {Promise<void>}
 */
async function processQuickOrder(action, data, $scope) {

    let $target = $scope.find('.js-quick-order__result');
    let $form = $scope.find('.js-quick-order__upload-form');
    let $loading = $scope.find('.js-quick-order__loading').attr('hidden', false);

    await fetch(action, {
        method: 'POST',
        body: data,
    }).then((res) => {
        return res.clone().json()
    }).then((res) => {

        // Fill result modal with response content.
        if (res.success) {
            $target.html(res.html);
            let $cartForm = $scope.find('.js-cart-detail__form');

            // Enable link to cart if cart is not in scope
            if ($cartForm.length < 1) {
                $target.find('.js-link-cart').attr('data-dismiss', '');
            }
            // Enable link to cart if wrong cart is open
            else {
                let cartId = $cartForm.data('cart-id')
                if (cartId !== $target.find('.js-link-cart').data('cart-id')) {
                    $target.find('.js-link-cart').attr('data-dismiss', '');
                }
            }
        }

        $form[0].reset();

        $scope.find('.js-quick-order__upload-modal').modal('hide');

        $loading.attr('hidden', true);
    });
}