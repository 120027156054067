"use strict";
import {onEnterViewPort} from "../libs/@elements/viewport-utils";

export function initInScope($scope) {
    $scope.find('.js-viewport-video').each(function () {
        let $this = $(this);

        onEnterViewPort($this, function ($item) {
            renderTemplate($item);
        }, {offset: 200});
    });
}

function renderTemplate(container) {
    let $template = container.find('.js-viewport-video__template');
    if ($template) {
        container[0].innerHTML = $template[0].innerHTML;
    }
}