'use strict'

export function initInScope($scope) {
    let $container = $scope.find('.js-per-page');
    const perPageParam = 'perPage';
    const pageParam = 'page';
    let url = new URL(document.location);
    const overrideParam = (param, value) => {
        url.searchParams.delete(param);
        url.searchParams.append(param, value);
    }
    
    if(url.searchParams.has(perPageParam)){
        let value = url.searchParams.get(perPageParam);

        $('.js-per-page__item').each(function (){
            if($(this).data('value').toString() === value.toString()){
                $(this).addClass('is-active');
            }
        });
    }
    else{
        $('.js-per-page__item').each(function (){
            if($(this).data('value').toString() === '24'){
                $(this).addClass('is-active');
            }
        });
    }

    $container.find('.js-per-page__item').on('click', function() {
        let value = $(this).data('value');

        let currentPerPage = url.searchParams.get(perPageParam)
        let currentPage = url.searchParams.get(pageParam)

        overrideParam(perPageParam, value)

        if (value === 24 && currentPerPage === '48' && currentPage) {
            overrideParam(pageParam, currentPage * 2 - 1)
        }
        else if (value === 48 && currentPerPage === '24' && currentPage) {
            overrideParam(pageParam, (currentPage - 1) / 2 + 1)
        }

        window.location = url;
    });
}