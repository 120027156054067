"use strict";

export function initInScope($scope) {
    if (matchMedia("(pointer: coarse)").matches) {
        $scope.find('.js-console__item').on('click', function (evt) {

            var $this = $(this),
                console = $this.closest('.js-console');

            if(!$this.hasClass('is-open')) {
                evt.preventDefault();
                console.find('.js-console__item').removeClass('is-open');

                $this.addClass('is-open');
            } else {
                $this.removeClass('is-open');
            }
        });
    }
}

