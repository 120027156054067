"use strict";

import loadScript from "../load-script";
import {getPrefixedDataSet} from '../data-set-utils';

const defaultOptions = {
    video: false,
    animateThumb: false,
    download: false,
    counter: false
};

const defaultSelectors = {
    base: '.js-lightbox',
    item: '.js-lightbox__item'
};

export function createInitInScope(selectors = defaultSelectors, options) {
    return function ($scope) {
        return $scope.find(selectors.base).each(function () {
            let $lightbox = $(this);
            let $lightboxItems = $lightbox.find(selectors.item);

            let elementOptions = {
                ...defaultOptions,
                ...options,
                ...getPrefixedDataSet('lightbox', $lightbox)
            };

            import('lightgallery').then(function () {

                if (elementOptions.video) {
                    import('vimeo-froogaloop2');
                    import('lg-video');
                }

                $lightbox.lightGallery({
                    ...elementOptions,
                    selector: selectors.item,
                    videojs: elementOptions.video,
                });

                //prevent isClickable
                $lightboxItems.on('click', function (evt) {
                    evt.stopImmediatePropagation();
                });
            });
        });
    }
}

export const initInScope = createInitInScope();
