"use strict";

let toggleTarget;

export function initInScope($scope) {

    $scope.find('.js-toggle-click').on('click', function (e) {

        toggleTarget = $(this).data('toggle-target');
        let $toggleTarget = $($(this).data('toggle-target'));

        if($toggleTarget.hasClass('is-open')){
            $toggleTarget.removeClass('is-open');
            $('body').off('click', bodyClickHandler);
        }else{
            if($toggleTarget.find('input').length > 0){
                $toggleTarget.find('input').focus();
            }
            $toggleTarget.addClass('is-open');
            $('body').on('click', bodyClickHandler);
        }

    });

    function bodyClickHandler (evt) {
        if ($(evt.target).parents(toggleTarget).length < 1 &&
            !$(evt.target).is(toggleTarget) &&
            !$(evt.target).hasClass('js-toggle-click') &&
            $(evt.target).parents('.js-toggle-click').length < 1) {
            evt.preventDefault();

            $(toggleTarget).removeClass('is-open');
            $('body').off('click', bodyClickHandler);
        }
    }
}