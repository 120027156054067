'use strict'

export function initInScope($scope) {
    const
        $clickTriggers  = $scope.find('.js-submitForm__click'),
        $changeTriggers = $scope.find('.js-submitForm__change');

    $clickTriggers.on('click', (e) => {
        const
            $target     = $(e.currentTarget),
            $targetForm = $($target.data('submitform-target'));

        e.preventDefault();
        $targetForm.submit();
    })

    $changeTriggers.on('click', (e) => {
        const
            $target     = $(e.currentTarget),
            $targetForm = $($target.data('submitform-target'));
        e.preventDefault();
        $targetForm.submit();
    })
}
