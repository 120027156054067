"use strict";

import $ from 'jquery';
import 'slick-carousel';
import {loadImg} from "../libs/@elements/lazy-img";
import {onEnterViewPort} from "../libs/@elements/viewport-utils";

export function initInScope($scope) {
    let $slider = $scope.find('.js-hero-teaser-slider');

    $slider.each(function () {
        let $slider = $(this),
            slidesToShow = 1,
            slidesToShowMobile = 1,
            arrowPrevMarkup = '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
            arrowNextMarkup = '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>';

        if($slider.data('slides-to-show')){
            slidesToShow = $slider.data('slides-to-show');
        }

        if($slider.data('slides-to-show-mobile')){
            slidesToShowMobile = $slider.data('slides-to-show-mobile');
        }

        if($slider.data('styled-arrows')){
            arrowPrevMarkup = '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-long-left"></span></button>';
            arrowNextMarkup = '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-long-right"></span></button>';
        }

        let sliderOptions = {
            nextArrow: arrowNextMarkup,
            prevArrow: arrowPrevMarkup,
            infinite: true,
            rows: 0,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: true,
            customPaging: function(slider, i) {
                return '<button type="button" role="button"></button>';
            }
        };

        if ($slider.data('autoplay-speed') !== null) {
            sliderOptions.autoplay = true;
            sliderOptions.autoplaySpeed = $slider.data('autoplay-speed');
        }

        const $tabPaneParent = $slider.closest('.tab-pane:not(.active)');
        const $collapseParent = $slider.closest('.collapse');
        const $modalParent = $slider.closest('.modal');
        
        // Initialize the slick slider with the options
        $slider.slick(sliderOptions);
    });
}

function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}