"use strict";

let defaultOptions = {
    offset: 0
};
let defaultSelectors = {
    link: '.js-scroll-to',
    dataOffset: 'scrollto-offset'
};


export function init(options = defaultOptions, selectors = defaultSelectors) {
    defaultOptions = options;
    defaultSelectors = selectors;
}

export function initInScope($scope) {

    $scope.find(defaultSelectors.link).on('click', function (evt) {
        evt.preventDefault();

        let $anchor = $(this);
        let targetSelector = $anchor.attr('href');
        let $target = $(targetSelector);
        let scrollOffset = $anchor.data('scrollto-offset') ? $anchor.data('scrollto-offset') : defaultOptions.offset;

        let $collapseParent = $target.closest('.collapse');
        let $tabPaneParent = $target.closest('.tab-pane');

        if ($collapseParent && $collapseParent.length) {
            // only preventDefault when target is in a collapse - else use the native behavior
            evt.preventDefault();

            if (!$collapseParent.hasClass('show')) {
                $collapseParent.one('shown.bs.collapse', function (e) {
                    scrollTo($target, scrollOffset, null, callback);
                });

                $collapseParent.collapse('show');
            } else {
                scrollTo($target, scrollOffset, null, callback);
            }

        }else if($tabPaneParent && $tabPaneParent.length){

            if($tabPaneParent.hasClass('show')){
                scrollTo($target, scrollOffset, null, callback);

            }else{
                $tabPaneParent.one('shown.bs.tab', function (e) {
                    scrollTo($target, scrollOffset - 220, null, callback);
                });
            }

            $('.nav[role="tablist"] a[href="' +targetSelector+ '"]').tab('show');

            $tabPaneParent.tab('show');

        }else{
            scrollTo($target, scrollOffset, null, callback);

        }

        function callback() {
            console.log('scrollto callback');
        }

    });
}

export function scrollTo ($target, offset = 0, focusTarget, callback) {
    if (!$target || !$target.length) {
        console.warn("Can't scroll to '" + $target + "'. Target element not found");
        return;
    }

    let callbackAlreadyFired;

    let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 100;

    let timeout = setTimeout(function () {
        $('html, body').stop().animate({
                scrollTop: $target.offset().top + offset
            },
            duration,
            'swing',
            function () {
                if (callback && !callbackAlreadyFired) {
                    callback();
                    callbackAlreadyFired = true;
                }

                if (focusTarget) {
                    focus($target);
                }
            }
        );
        clearTimeout(timeout);
    }, 0);
}

function focus ($target) {
    if (typeof $target.attr('tabindex') == 'undefined') {
        $target.attr('tabindex', '-1');
    }
    $target.focus();
}
