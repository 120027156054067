"use strict";

export function initInScope($scope) {
    $scope.find('.js-create-cart').each(function () {
        let $container = $(this),
            $target = $($container.data('target')),
            $input = $container.find('.js-create-cart__input'),
            $form = $container.find('.js-create-cart__form'),
            $url = $form.attr('action');
        $input.on('focus', function () {
            $target.collapse('show');
            $('body').on('click', bodyClickHandler);
        });

        $input.on('change', function () {
           if ($input.val().length > 0 ) {
               $input.addClass('has-value');
           } else {
               $input.removeClass('has-value');
           }
        });

        $target.on('hide.bs.collapse', function () {
            $input.val('');
            $input.removeClass('has-value');

            $('body').off('click', bodyClickHandler);
        });

        $form.on('submit', function(ev) {
            ev.preventDefault();
            let fd = new FormData(ev.target);
            let request = fetch($url, {
                method: 'post',
                body: fd
            });

            request.then(response => response.json())
                .then(result => {
                    if (result.success) {
                        let content = result.content || result.html;

                        if (content) {
                            let resultDiv = $scope.find('.cart-list')
                            resultDiv.html(content);
                            $target.collapse('hide');
                        }
                    }
                }).catch(e => {
                    console.warn(e);
                }
            );
        });

        function bodyClickHandler (evt) {
            if ($(evt.target).parents('.js-create-cart').length < 1 && !$(evt.target).hasClass('.js-create-cart')) {
                evt.preventDefault();

                $target.collapse('hide');
            }
        }
    });
}