'use strict'
import {default as throwError} from "../libs/@elements/throw-error";
import {translate} from "../libs/@elements/translations";
import {getDate, setMinDate, setMaxDate} from "../libs/@elements/datepicker";
import {init as initCartCount} from "./cart";

export function initInScope($scope) {
    const $selectCartTrigger = $scope.find('.js-cart-overview__input');
    const $ajaxForm = getAjaxForm($("body"));

    if (!$ajaxForm.length) {
        throwError(translate('ajax.form.not.found!'))
        return;
    }

    initGlobalListener($ajaxForm);

    /**
     * submitting the cart change
     */
    $selectCartTrigger.on('change', function () {
        $ajaxForm.find('.js-ajax-form__form').trigger('submit');
    });

    $scope.find(".js-carts-from__input").on("change", function () {
        const $fromPicker = $scope.find(".js-carts-from__datepicker");
        const $toPicker = $scope.find(".js-carts-to__datepicker");

        setMinDate($toPicker, getDate($fromPicker));
    });

    $scope.find(".js-carts-to__input").on("change", function () {
        const $fromPicker = $scope.find(".js-carts-from__datepicker");
        const $toPicker = $scope.find(".js-carts-to__datepicker");

        setMaxDate($fromPicker, getDate($toPicker));
    });
}

let globalInitialized = false;

function initGlobalListener($ajaxForm) {
    if (globalInitialized) {
        return;
    }

    globalInitialized = true;

    $ajaxForm.on("success.ajax-form", function (...args) {
        initCartCount()
    });
}

function getAjaxForm($scope) {
    return $scope.find('.js-cart-overview__ajaxform')
}