'use strict'
import loadScript from "../libs/@elements/load-script";
import {loadGoogleMapsAPI} from "../libs/@elements/google-map";
import {debounce} from "throttle-debounce"


export async function initInScope($scope) {
    const $ajaxForm = $scope.find('.js-store-search__ajax-form-wrapper');

    if ($ajaxForm.length) {
        const
            $form = $ajaxForm.find('.js-ajax-form__form'),
            $input = $ajaxForm.find('.js-store-search__input'),
            $button = $ajaxForm.find('.js-store-search__submit'),
            $countryInput = $ajaxForm.find('#store-search-country'),
            $latInput = $ajaxForm.find('#store-search-lat'),
            $lngInput = $ajaxForm.find('#store-search-lng'),
            $reset = $ajaxForm.find('.js-store-search__reset'),
            $filter = $ajaxForm.find("[name='filter']");

        let queryAddress = '';
        await loadScript("https://maps.googleapis.com/maps/api/js?key=" + _config.googleMapAPIKey + "&language=" + _config.lang + "&libraries=places")
        let autocomplete = new google.maps.places.Autocomplete($input[0]);
        const geocoder = new google.maps.Geocoder();

        $form.on('submit', (e) => {
            e.preventDefault();
            getLatLng();
        })

        const codeAddress = address => {
            return new Promise((resolve, reject) => {
                geocoder.geocode({address: address}, (results, status) => {
                    if (status === 'OK') {
                        resolve(results);
                    } else {
                        reject(status);
                    }
                });
            });
        };

        const getLatLng = debounce(300, async (e) => {
            let currentValue = $input.val();
            if (currentValue && currentValue !== queryAddress) {
                const place = await codeAddress(currentValue);
                if (place) {
                    const newplace = place[0];
                    console.log("geoc", newplace)
                    $countryInput.val(JSON.stringify(place[0].address_components));
                    $latInput.val(place[0].geometry.location.lat());
                    $lngInput.val(place[0].geometry.location.lng());
                    $input.val(place[0].formatted_address);
                    queryAddress = place[0].formatted_address;
                    $form.trigger('submit')
                }
            }

        })

        autocomplete.addListener('place_changed', function () {
            try {
                let place = autocomplete.getPlace();
                const newplace = place
                console.log("auto", newplace);
                queryAddress = place.formatted_address;
                $countryInput.val(JSON.stringify(place.address_components));
                $latInput.val(place.geometry.location.lat())
                $lngInput.val(place.geometry.location.lng())
                $form.trigger('submit')
            } catch {

            }
        });

        $input.on('keypress', (e) => {
            if (e.which == 13) {
                e.preventDefault();
                e.stopPropagation();
                if ($input.val() || $('.pac-container:visible').length) {
                    getLatLng(e);
                } else {
                    $latInput.val('');
                    $lngInput.val('');
                    $form.trigger('submit')
                }
            }
        })

        $input.on('change', (e) => {
            e.preventDefault();
            e.stopPropagation();
            // getLatLng(e)
        })

        $button.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            if ($input.val() || $('.pac-container:visible').length) {
                getLatLng(e);
            } else {
                $latInput.val('');
                $lngInput.val('');
                $form.trigger('submit')
            }
        })
        $reset.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            $latInput.val('');
            $lngInput.val('');
            $countryInput.val('');
            $input.val('');
            $filter.val('');
            $form.trigger('submit')

        })
    }

}