"use strict";

export function initInScope($scope) {
    $scope.find('.js-filter-search').each(function () {
        let $list = $(this).find('.js-filter-search__list'),
            $searchInputHidden = $(this).find('.js-filter-search__input--hidden'),
            $searchInput = $(this).find('.js-filter-search__input'),
            $items = $list.find('.js-filter-search__list-item');

        $searchInput.on('input', function () {
            filterList($searchInput.val(), $items)

            $searchInputHidden.val($searchInput.val());
        });

        filterList($searchInput.val(), $items);
    });
}

function filterList (keyword, $items) {
    if (!(keyword === '' || keyword === undefined)) {
        $items.filter((index, item) => {
            if ($(item).data('label').toLowerCase().startsWith(keyword.toLowerCase())) {
                $(item).attr('hidden', false);

            } else {
                $(item).attr('hidden', true);
            }
        });
    } else {
        $items.map((index, item) => $(item).attr('hidden', false));
    }
}
