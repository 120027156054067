import initModulesInScope from "../libs/@elements/init-modules-in-scope";
import fetch from "../libs/@elements/fetch";

function fetchAndAppend(url, $container) {
    fetch(url)
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            $container.html(response.html);
            initModulesInScope($container);
        })
        .catch((exception) => {
            $container.empty();
            console.log(exception);
            console.error(arguments);
        });
}

export function initInScope($scope) {
    const template = $(".js-loading-overlay-template").html();

    $scope.find("[data-ajax-load]").each(function () {
        const $container = $(this);
        $container.append($(template));

        fetchAndAppend($container.data("ajax-load"), $container);

        $container.triggerHandler("ajax-load:loaded");
    });

    $scope.find(".js-ajax-load-action").on("click", function (event) {
        const $action = $(this);
        const $container = $scope.find($action.data("target"));

        $container.append($(template));

        fetchAndAppend($action.attr("href"), $container);

        event.preventDefault();
        return false;
    });
}