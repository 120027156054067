"use strict";

import 'slick-carousel';
import {loadImg} from "../libs/@elements/lazy-img";
import {onEnterViewPort} from "../libs/@elements/viewport-utils";

export function initInScope ($scope) {
    let $slider  = $scope.find('.js-product-teaser-main-slider');
    $slider.each(function () {
        let $sliderMain = $(this).find('.js-product-teaser-main-slider__main');
        let $sliderNav = $(this).find('.js-product-teaser-main-slider__nav');

        let sliderOptionsMain = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
            prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
            fade: true,
            speed: 200,
            asNavFor: $sliderNav,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        arrows: true
                    }
                }
            ]
        };

        let sliderOptionsNav = {
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: $sliderMain,
            dots: true,
            infinite: false,
            centerPadding: '50px',
            focusOnSelect: true,
            speed: 200,
            nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
            prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        centerPadding: '5px'
                    }
                }
            ]
        };

        initSlider($sliderMain, sliderOptionsMain);
        initSlider($sliderNav, sliderOptionsNav);

    });

}

function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}

