"use strict";

export function initInScope($scope) {

    $scope.find('.js-form-auto-submit').on('change', function (e) {
        const $this = $(this);

        if ($this.data("field-target") && $this.data("value-target")) {
            $scope.find($this.data("field-target")).val($this.attr("name"));
            $scope.find($this.data("value-target")).val($this.val());
        }

        $(this).closest('form').submit();
    });

}