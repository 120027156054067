'use strict'

export function initInScope($scope) {
    const
        $popovers   = $scope.find('[data-toggle="popover"]');

    $popovers.popover({
        html: true
    })

    $('body').on('click', function (e) {
        $('[data-toggle=popover]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });

    $popovers.map((index, popover) => {
        let $this = $(popover);
        if ($this.data('trigger') === 'manual-hover') {
            $this.on('mouseenter', function (evt) {
                $this.popover("show");

                $(".popover").on('mouseleave', function () {
                    $this.popover('hide');
                });
            }).on('mouseleave', function () {
                setTimeout(function () {
                    if (!$('.popover:hover').length) {
                        $this.popover('hide');
                    }
                }, 300);
            });
        }
    });
}