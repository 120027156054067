'use strict'
import asyncAppend from "../libs/@elements/async-append";
import fetch from "../libs/@elements/fetch"
import { getLoadingContainer, removeLoadingContainer } from "./loading";
import { loadParsley, isParsleyForm, isValid } from "../libs/@elements/parsley-bootstrap-validation";
import formDataEntries from 'form-data-entries';
import {responseTracking} from "../libs/@elements/tracking";

let $form, $sidebar, $notificationContainer, updateUrl;

export function initInScope($scope) {
    const $container = $scope.find('.js-checkout__container');

    if($container.length){
        const $button     = $container.find('.js-checkout__btn');

        $form                   = $container.find('.js-checkout__form');
        $sidebar                = $container.find('.js-checkout__sidebar');
        $notificationContainer  = $container.find('.js-checkout__notifications');
        updateUrl               = $container.data('update-url');

        loadParsley().then(() => {
            $button.on('click', (e) => {
                if (isParsleyForm($form) && !isValid($form)) {
                    e.preventDefault();
                }
            })
        });
    }

    const $addressModal   = $scope.find('.js-checkout__address-modal');

    if($addressModal && $container){
        const $addressBtns  = $addressModal.find('.js-checkout__address-select');

        $addressBtns.on('click', (e) => {
            const addressId = $(e.delegateTarget).data('checkout-address-id');
            $container.find('.js-checkout__address-input').val(addressId);
            $addressModal.modal('hide');
            updateCart('address', formDataEntries($form[0]))
        })
    }

    const $checkbox = $('#orderAgbCheckbox');
    const $orderButton = $('.checkout__btn');

    // Initially disable the order button
    // $orderButton.addClass('btn-disabled').prop('disabled', true);

    $checkbox.change(function() {
        if ($(this).is(':checked')) {
            // Enable the button
            $orderButton.removeClass('btn-disabled').prop('disabled', false);
        } else {
            // Disable the button
            $orderButton.addClass('btn-disabled').prop('disabled', true);
        }
    });

    bindUpdateActions($scope);
}

function bindUpdateActions($scope) {

    const $deleteBtn = $scope.find('.js-checkout__delete');
    $deleteBtn.on('click', (e) => updateCart('delete', [["articleId", $(e.delegateTarget).data('article-id')], ["cartId", $(e.delegateTarget).data('cart-id')]]))

    const $formChanger = $scope.find('.js-checkout__change');

    $formChanger.on('change', () => {
        updateCart('update', formDataEntries($form[0]))
    })
}

async function updateCart(action = 'update', payload = []){
    payload.push(['action', action])

    const request = fetch(updateUrl, {
        body: new URLSearchParams(payload)
    });

    responseTracking(request);

    return await asyncAppend({
        $target: {
            'form'      : $form,
            'sidebar'   : $sidebar
        },
        $loading: $($(getLoadingContainer()).appendTo($form), $(getLoadingContainer()).appendTo($sidebar)),
        $notifications: $notificationContainer
    }, request).finally(() => {
        removeLoadingContainer($form);
        removeLoadingContainer($sidebar);
    })
}