"use strict";

import fetch from "../libs/@elements/fetch";
import formDataEntries from 'form-data-entries';

export function initInScope($scope) {
    $scope.find(".js-availability__form").each(function () {
        bindEvents($(this));
    })
}

function bindEvents($form) {
    const $button = $form.find('.js-availability__check-button');
    const url = $button.data("href");

    $button.on('click', (e) => {
        checkAvailabilityHandler($form, e, url, $($form.data('additional-form')));
    });

    $form.find(".js-availability__check-quantity").on("keypress", function (event) {
        if (event.which != 13) {
            return;
        }

        checkAvailabilityHandler($form, event, url, $($form.data('additional-form')));
        return false;
    });
}

/**
 * Click handler for check availability button.
 *
 * @param $scope
 * @param e
 * @param url
 */
function checkAvailabilityHandler($scope, e, url, additionalForm) {
    e.preventDefault();

    getAvailability(url, $scope, additionalForm)
        .then(result => {
            if (result.success) {

                // Replace html content with html response.
                $scope.find('.js-availability__group').remove();
                $scope.append(result.html);

                bindEvents($scope);

                // Workaround to move cursor position in input field to the end of input value.
                let $quantityInput = $scope.find('.js-availability__check-quantity').focus();
                let quantity = $quantityInput.val();
                $quantityInput.val('');
                $quantityInput.val(quantity);

                // // Redo request when changing value in input field.
                // $quantityInput.on('input', (e) => {
                //     let $group = $scope.find('.js-availability__group');
                //
                //     let availableQuantity = $group.data('available-quantity');
                //     let availabilityStatus = $group.data('availability-status');
                //
                //     let inputQuantity = $(e.target).val();
                //
                //     if (availabilityStatus === 'available' || availabilityStatus === 'stock-incoming' || (availabilityStatus === 'not-available' && inputQuantity <= availableQuantity)) {
                //         // Trigger click event, which performs the request and updates the html.
                //         $scope.find('.js-availability__check-button').trigger('click');
                //     }
                // });
            } else {
                $scope.find('.js-availability__error-message').prop('hidden', false);
            }

        })
        .catch((exception) => {
            console.log(exception);
        });
}

/**
 * Fetches data from url, with form data.
 *
 * @param url
 * @param $form
 */
async function getAvailability(url, $form = null, additionalForm) {
    if ($form.find(".js-availability__check-quantity").val() == 0) {
        return;
    }

    let payload = {};

    if ($form) {
        payload = formDataEntries($form.get(0));
    }

    if (additionalForm) {
        console.log(additionalForm)
        console.log(payload)
        additionalForm.find('.js-variant-select__item-amount').each(function (index, input) {
            payload.push([$(input).attr('name'), $(input).val()]);

            console.log(payload)
        });
    }

    const response = await fetch(url, {
        body: new URLSearchParams(payload)
    }).catch(function (exception) {
        console.log(exception);
    });

    return response.json();
}