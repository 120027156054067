'use strict'

export function initInScope($scope) {
    const
        $lastFilter = $scope.find('.js-product-grid-filter__last-input'),
        $dropdowns  = $scope.find('.js-product-grid-filter__dropdown');

    $dropdowns.on('show.bs.dropdown', (e) => {
        $lastFilter.val($(e.target).data('product-grid-filter-last-input'))
    })

    $dropdowns.each((_,el) => {
        const $el = $(el);

        if($el.hasClass('js-product-grid-filter__dropdown--show')){
            $el.find('button').dropdown('show');
        }
    })
}