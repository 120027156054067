"use strict";

import bootbox from 'bootbox';

export function init(){
//hotspot detail box position
    if(matchMedia('(min-width: 768px)').matches) {
        let $hotspots = $('.js-hotspots__hotspots');
        $(window).on('load', function () {
            $hotspots.each(function () {
                let $this = $(this),
                    $detail = $(this).find('.hotspots__detail'),
                    container = $this.closest('.js-hotspots__container');

                if(container.length) {
                    if($this.position().top > $detail.outerHeight()) {
                        $this.addClass('hotspots__hotspot--top');
                    }

                    if($this.position().left < container.outerWidth()/2 ) {
                        $this.addClass('hotspots__hotspot--right');
                    }
                }
            });
        });
    }
}

export function initInScope($scope) {

    $('.js-hotspots').each(function () {
        let $container = $(this);
        let $details = $container.find('.js-hotspots__detail');
        let $hotspots = $container.find('.js-hotspots__hotspots');
        let $toggles = $container.find('.js-hotspots__toggle');


        $toggles.on('click', function () {
            let $this = $(this);

            if ($this.hasClass('is-open')) {
                $toggles.removeClass('is-open');
                closeAll();
            } else {
                setActiveHotspotById($this.data('hotspots-id'));
            }
        });

        $container.find('.js-hotspots__close').on('click', closeAll);

        function setActiveHotspotById(id = null) {
            $container.removeClass('is-open');

            $details.removeClass('is-open')
                .attr('aria-hidden', 'false');

            $hotspots.removeClass('is-open')
                .attr('aria-hidden', 'false');

            $toggles.removeClass('is-open')
                .attr('aria-expanded', 'false');

            if (id !== null) {
                // open
                $container.addClass('is-open');

                $details
                    .filter(`[data-hotspots-id="${ id }"]`)
                    .addClass('is-open')
                    .attr('aria-hidden', 'true');

                $details.filter(`[data-hotspots-id="${ id }"]`).closest('.js-hotspots__hotspots').addClass('is-open');

                $toggles
                    .filter(`[data-hotspots-id="${ id }"]`)
                    .addClass('is-open')
                    .attr('aria-expanded', 'true');

                if (matchMedia('(max-width: 767px)').matches) {
                    let detail = $details.filter(`[data-hotspots-id="${ id }"]`);

                    bootbox.dialog({
                        message: detail,
                        onEscape: function() { console.log("Close"); },
                        backdrop: true,
                        callback: closeAll
                    });

                }
            }
        }

        function closeAll() {
            setActiveHotspotById(null);
        }
    });
}