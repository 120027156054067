"use strict";

import {scrollTo, focus} from "./scrollTo";


export function initInScope($scope) {

    let $ajaxForm = $scope.find('.js-ajax-form-scroll');

    $ajaxForm.on('success.ajax-form', function() {
        let $wrapper = $(this);
        let offset = 230;
        let $target = $wrapper.find('.js-ajax-form__result');
        scrollTo($target, offset, function () {
            focus($target);
        });
    })
}
