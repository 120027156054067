"use strict"

export function initInScope($scope) {
    $scope.find(".js-variant-select").each(function () {
        let $variantSelection = $(this);

        $variantSelection.find('.js-variant-select__remove').on('click', function () {
            let target = $($(this).data('target-id'));
            target.prop('checked', false)
            target.trigger('change')
        });
    });
}