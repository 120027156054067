"use strict";

export function initInScope($scope) {
    let $button = $scope.find('.js-copy-to-clipboard');

    $button.on('click', function () {
        navigator.clipboard.writeText($button.data('content'))

        $button.addClass('is-active');

        let t = setTimeout(function () {
            $button.removeClass('is-active');

            clearTimeout(t);
        }, 1000);
    });
}