"use strict";

import $ from 'jquery'
import 'slick-carousel';
import {loadImg} from "../libs/@elements/lazy-img";
import {onEnterViewPort} from "../libs/@elements/viewport-utils";


export function initInScope($scope) {

    let $slider = $scope.find('.js-gallery-slider');

    $slider.each(function () {
        let $slider = $(this),
            slidesToShow = 1.15,
            arrowPrevMarkup = '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
            arrowNextMarkup = '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>';

        if($slider.data('slides-to-show')){
            slidesToShow = $slider.data('slides-to-show');
        }

        if($slider.data('styled-arrows')){
            arrowPrevMarkup = '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-long-left"></span></button>';
            arrowNextMarkup = '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-long-right"></span></button>';
        }

        let sliderOptions = {
            nextArrow: arrowNextMarkup,
            prevArrow: arrowPrevMarkup,
            infinite: false,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        infinite: false,
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        infinite: false,
                        slidesToShow: 1
                    }
                }
            ]
        };


        initSlider($slider, sliderOptions);

    });

}

function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}
