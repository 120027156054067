"use strict";
import $ from 'jquery'
import { default as throwError, throwWarning } from "../throw-error";
import {  getConfigValue } from '../config-utils';
import loadJQueryUi from '../load-jquery-ui';
import {translate} from '../translations';


const errorExampleDate ='1970-10-20';

export function initInScope ($scope) {
    
    let $forms = $scope.find('.js-parsley');
    loadParsley().then(function () {
        Parsley.addMessages('de', {
            defaultMessage: "Die Eingabe scheint nicht korrekt zu sein.",
            type: {
              email:        "Die Eingabe muss eine gültige E-Mail-Adresse sein.",
              url:          "Die Eingabe muss eine gültige URL sein.",
              number:       "Die Eingabe muss eine Zahl sein.",
              integer:      "Die Eingabe muss eine Zahl sein.",
              digits:       "Die Eingabe darf nur Ziffern enthalten.",
              alphanum:     "Die Eingabe muss alphanumerisch sein."
            },
            notblank:       "Die Eingabe darf nicht leer sein.",
            required:       "Dies ist ein Pflichtfeld.",
            pattern:        "Die Eingabe scheint ungültig zu sein.",
            min:            "Die Eingabe muss größer oder gleich %s sein.",
            max:            "Die Eingabe muss kleiner oder gleich %s sein.",
            range:          "Die Eingabe muss zwischen %s und %s liegen.",
            minlength:      "Die Eingabe ist zu kurz. Es müssen mindestens %s Zeichen eingegeben werden.",
            maxlength:      "Die Eingabe ist zu lang. Es dürfen höchstens %s Zeichen eingegeben werden.",
            length:         "Die Länge der Eingabe ist ungültig. Es müssen zwischen %s und %s Zeichen eingegeben werden.",
            mincheck:       "Wählen Sie mindestens %s Angaben aus.",
            maxcheck:       "Wählen Sie maximal %s Angaben aus.",
            check:          "Wählen Sie zwischen %s und %s Angaben.",
            equalto:        "Dieses Feld muss dem anderen entsprechen."
          });
          Parsley.addMessages('fr', {
            defaultMessage: "Cette valeur semble non valide.",
            type: {
              email:        "Cette valeur n'est pas une adresse email valide.",
              url:          "Cette valeur n'est pas une URL valide.",
              number:       "Cette valeur doit être un nombre.",
              integer:      "Cette valeur doit être un entier.",
              digits:       "Cette valeur doit être numérique.",
              alphanum:     "Cette valeur doit être alphanumérique."
            },
            notblank:       "Cette valeur ne peut pas être vide.",
            required:       "Ce champ est requis.",
            pattern:        "Cette valeur semble non valide.",
            min:            "Cette valeur ne doit pas être inférieure à %s.",
            max:            "Cette valeur ne doit pas excéder %s.",
            range:          "Cette valeur doit être comprise entre %s et %s.",
            minlength:      "Cette chaîne est trop courte. Elle doit avoir au minimum %s caractères.",
            maxlength:      "Cette chaîne est trop longue. Elle doit avoir au maximum %s caractères.",
            length:         "Cette valeur doit contenir entre %s et %s caractères.",
            mincheck:       "Vous devez sélectionner au moins %s choix.",
            maxcheck:       "Vous devez sélectionner %s choix maximum.",
            check:          "Vous devez sélectionner entre %s et %s choix.",
            equalto:        "Cette valeur devrait être identique."
          });
          Parsley.addMessages('nl', {
            defaultMessage: "Deze waarde lijkt onjuist.",
            type: {
              email:        "Dit lijkt geen geldig e-mail adres te zijn.",
              url:          "Dit lijkt geen geldige URL te zijn.",
              number:       "Deze waarde moet een nummer zijn.",
              integer:      "Deze waarde moet een nummer zijn.",
              digits:       "Deze waarde moet numeriek zijn.",
              alphanum:     "Deze waarde moet alfanumeriek zijn."
            },
            notblank:       "Deze waarde mag niet leeg zijn.",
            required:       "Dit veld is verplicht.",
            pattern:        "Deze waarde lijkt onjuist te zijn.",
            min:            "Deze waarde mag niet lager zijn dan %s.",
            max:            "Deze waarde mag niet groter zijn dan %s.",
            range:          "Deze waarde moet tussen %s en %s liggen.",
            minlength:      "Deze tekst is te kort. Deze moet uit minimaal %s karakters bestaan.",
            maxlength:      "Deze waarde is te lang. Deze mag maximaal %s karakters lang zijn.",
            length:         "Deze waarde moet tussen %s en %s karakters lang zijn.",
            equalto:        "Deze waardes moeten identiek zijn."
          });
          Parsley.addMessages('it', {
            defaultMessage: "Questo valore sembra essere non valido.",
            type: {
              email:        "Questo valore deve essere un indirizzo email valido.",
              url:          "Questo valore deve essere un URL valido.",
              number:       "Questo valore deve essere un numero valido.",
              integer:      "Questo valore deve essere un numero valido.",
              digits:       "Questo valore deve essere di tipo numerico.",
              alphanum:     "Questo valore deve essere di tipo alfanumerico."
            },
            notblank:       "Questo valore non deve essere vuoto.",
            required:       "Questo valore è richiesto.",
            pattern:        "Questo valore non è corretto.",
            min:            "Questo valore deve essere maggiore di %s.",
            max:            "Questo valore deve essere minore di %s.",
            range:          "Questo valore deve essere compreso tra %s e %s.",
            minlength:      "Questo valore è troppo corto. La lunghezza minima è di %s caratteri.",
            maxlength:      "Questo valore è troppo lungo. La lunghezza massima è di %s caratteri.",
            length:         "La lunghezza di questo valore deve essere compresa fra %s e %s caratteri.",
            mincheck:       "Devi scegliere almeno %s opzioni.",
            maxcheck:       "Devi scegliere al più %s opzioni.",
            check:          "Devi scegliere tra %s e %s opzioni.",
            equalto:        "Questo valore deve essere identico.",
            euvatin:        "Non è un codice IVA valido",
          });
        window.ParsleyValidator.setLocale($("html").attr('lang'));
        // checks if the input value is a valid date due to the local date format of jQuery UI datepicker
        if (!Parsley.hasValidator('localDate')) {
            Parsley.addValidator('localDate', {
                validateString: function(value) {
                    // load datepicker
                    if (value) {
                        // return promise so it rejects if loading fails
                        return loadJQueryUi().then(function () {
                            let datepickerLocalData = $.datepicker.regional[_config.lang || ''];
                            if (!datepickerLocalData) {
                                datepickerLocalData = $.datepicker.regional[''];
                            }

                            const dateFormat = datepickerLocalData.dateFormat;

                            // check if date is ok
                            try {
                                // the datepicker will throw an error if the date is not valid/not parsable
                                $.datepicker.parseDate(dateFormat, value);
                            } catch (error) {
                                // the error message is stored in the _translations-object
                                let errorMsg = translate('parsley.error.invalid-date-format');
                                let localizedFormat;
                                let formattedExampleDate = $.datepicker.formatDate(dateFormat, new Date(errorExampleDate));

                                // "yy" means four digit year
                                localizedFormat = dateFormat.replace('yy', 'yyyy');

                                // translate the abbreviations if the language is german
                                if (_config.lang == 'de') {
                                    localizedFormat = localizedFormat.replace(/y/gi, 'J')
                                        .replace(/d/gi, 'T')
                                        .replace(/m/gi, 'M');
                                }

                                // replace the placeholder for the format-string in the error message
                                errorMsg = errorMsg.replace(/\[format\]/i, localizedFormat);

                                // replace the placeholder for the date example in the error message
                                errorMsg = errorMsg.replace(/\[date\]/i, formattedExampleDate);

                                // throw an error with the customized error message, so the field is marked invalid
                                throw(errorMsg);
                            }
                        });

                    }
                }
            });
        }

        $forms.each((i, form) => {
            let $form = $(form);
            if($form.is('form')){
                $forms.parsley(options);
            } else {
                throwWarning('Element with Class .js-parsley is not Type FORM: ', $form)
                $form.find(':input[type=submit]').on('click', () => {
                    isValid($form);
                })
            }
        })
    });
}

function extendParsleyAccessibility(){
    window.Parsley.on('field:error', function (obj) {
        let parsleyId = obj.__id__;
        let fieldClass = obj.__class__;
        let $elements = obj.$elements || [obj.$element];

        if (fieldClass === 'ParsleyFieldMultiple') {
            parsleyId = 'multiple-' + obj.$element.data('parsley-multiple');
        }

        $elements.forEach(element => {
            $(element)
                .addClass('is-invalid')
                .removeClass('is-valid')
                .attr({
                    'aria-invalid': "true",
                    'aria-describedby': 'parsley-id-' + parsleyId
                });
        });
    });

    window.Parsley.on('field:success', function (obj) {
        let $elements = obj.$elements || [obj.$element];

        $elements.forEach(element => {
            $(element)
                .addClass('is-valid')
                .removeClass('is-invalid')
                .removeAttr('aria-invalid aria-describedby');
        });
    });
    window.Parsley.addValidator('filemimetypes', {
        requirementType: 'string',
        validateString: function (value, requirement, parsleyInstance) {

            if (!window.FormData) {
                alert('You are making all developpers in the world cringe. Upgrade your browser!');
                return true;
              }

            var file = parsleyInstance.$element[0].files;

            if (file.length == 0) {
                return true;
            }
            console.log(requirement);
            var allowedMimeTypes = requirement.replace(/\s/g, "").split('|');
            console.log(file[0].type);
            return allowedMimeTypes.indexOf(file[0].type) !== -1;

        },
        messages: {
            de: 'Ungültiger Dateityp (bitte laden Sie ausschließlich .jpg, .png, .pdf, Word oder zip Dateien hoch)',
            en: 'Invalid file type (please upload only .jpg, .png, .pdf, Word or zip files)',
            fr: "Type de fichier non valide (veuillez télécharger uniquement des fichiers .jpg, .png, .pdf, Word ou zip)",
            nl: "Ongeldig bestandstype (upload alleen .jpg, .png, .pdf, Word of zip bestanden)",
          }
    });


    

    window.Parsley.addValidator('maxFileSize', {
        validateString: function(_value, maxSize, parsleyInstance) {
          if (!window.FormData) {
            alert('You are making all developpers in the world cringe. Upgrade your browser!');
            return true;
          }
          var files = parsleyInstance.$element[0].files;
          return files.length != 1  || files[0].size <= maxSize * 1024;
        },
        requirementType: 'integer',
        messages: {
          de: 'Die Datei darf nicht größer als %s Kb sein.',
          en: 'This file should not be larger than %s Kb',
          fr: "Ce fichier est plus grand que %s Kb.",
          nl: "Het bestand mag niet groter zijn dan %s Kb.",
        }
      });
}


export const options = {
    successClass: 'has-success is-valid',
    errorClass: 'has-error is-invalid',
    classHandler: function ({$element}) {
        return $element.closest('.form-group, .js-parsley__form-group');
    },
    errorsContainer: function ({$element}) {
        let errorContainer = $element.closest('.form-group, .js-parsley__form-group').find('.form-errors, .js-parsley__form-errors');
        if (errorContainer && errorContainer.length > 0) {
            return errorContainer;
        }
    },
    // nur felder validieren die sichtbar sind
    excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden"
};

let promise;
export function loadParsley() {
    if (promise) {
        return promise;
    }

    promise = new Promise(function (resolve, reject) {
        import('parsleyjs').then(function () {
            extendParsleyAccessibility();
            if (getConfigValue('lang') && getConfigValue('lang') !== 'en') {
                import(/* webpackChunkName: "parsley-lang-package-" */'parsleyjs/dist/i18n/' + getConfigValue('lang') + '.js').then(function () {
                    resolve();
                }).catch(function (reason) {
                    /*fallback if there is no package*/
                    resolve();
                });
            } else {
                resolve();
            }
        }).catch(function (reason) {
            reject();
        });
    });

    return promise;
}

export function isParsleyForm ($form) {
    return $form.is('.js-parsley');
}

// make sure parsley is loaded if you call this function initially (e.g. wrap the call in a loadParsley.then())
export function isValid($form) {
    if(!$.fn.parsley){
        throwError(`Trying to Validate with Parsley, but Parsley is not Loaded! Try to wrap the call in a loadParsley.then().`);
        return
    }
    if($form.length > 1){
        throwWarning('isValid Function only accepts 1 Element! Auto selecting first Element in $scope.', $form)
        $form = $form.eq(0);
    }

    if($form.is('form')){
        if (!$form.data('Parsley')) {
            /* if the form is not initialized */
            $form.parsley(options);
        }
        return $form.data('Parsley').validate();
    }else{
        throwWarning(`Using parsley without form.`);
        let $inputs = $form.find(':input'),
            isValid = true;
        $inputs.each(function () {
            if(!$(this).prop("disabled") && $(this).parsley(options).validate() !== true) {
                isValid = false
            }
        });
        return isValid
    }
}


export function whenValid($scope) {
    return new Promise((resolve) => {
        let isValid = true;

        if($scope.length > 1){
            throwWarning('whenValid Function only accepts 1 Element! Auto selecting first Element in $scope.', $scope)
            $scope = $scope.eq(0);
        }

        return loadParsley().then(() => {
            if($scope.is('form')){
                if (!$scope.data('Parsley')) {
                    /* if the form is not initialized */
                    $scope.parsley(options);
                }
                isValid = $scope.data('Parsley').validate();
            }else{
                throwWarning(`Using parsley without form.`);
                $scope.find(':input').each(function () {
                    if(!$(this).prop("disabled") && $(this).parsley(options).validate() !== true) {
                        isValid = false
                    }
                });
            }

            resolve(isValid)
        })
    })
}

