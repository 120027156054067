export function initInScope($scope) {
    // if (!matchMedia('(max-width: 767px)').matches) {
    //     return;
    // }

    let activeTab = $scope.find('.js-center-active-tab').find('.active').closest('li');
    let navTab = $scope.find('.js-center-active-tab').find('li');

    center(navTab, activeTab);

    navTab.on('click', function () {
        center(navTab, $(this))
    });
}

function center(navTab, tab) {
    if(tab && tab.length) {
        let childPos = tab.offset().left,
            $parent = tab.parent(),
            parentScroll = $parent.scrollLeft(),
            offset = childPos + parentScroll - $parent.innerWidth()/2 + tab.width()/2;

        navTab.closest('ul').animate({ scrollLeft: offset }, 250);
    }
}
