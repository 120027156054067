'use strict'

import "../libs/@elements/menu-aim";

export function init($scope = $('body')) {
    const
        $navbar     = $scope.find('.js-nav'),
        $navItems   = $navbar.find('.js-nav__item')

    const activateSubmenu = (li) => {
        if(matchMedia('(max-width: 1199px)').matches){
            return
        }
        $(li).addClass('is-open')
    }

    const deactivateSubmenu = (li) => {
        if(matchMedia('(max-width: 1199px)').matches){
            return
        }
        $(li).removeClass('is-open')
    }

    const exitMenu = () => {
        if(matchMedia('(max-width: 1199px)').matches){
            return
        }
        $navItems.removeClass('is-open')
    }

    $navbar.menuAim({
        activate: activateSubmenu,  // fired on row activation
        deactivate: deactivateSubmenu, // fired on row deactivation
        exitMenu: exitMenu, // fired on menu deactivation
        submenuDirection: "below",
        tolerance: 1
    })
}

