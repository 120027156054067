"use strict";

export function initInScope($scope) {

    initAnchors($scope);

    $(window).on('resize', function () {
        initAnchors($scope);
    });
}

function initAnchors($scope) {
    $scope.find('.js-steps').each(function (index, container) {
        let anchors = matchMedia('(max-width: 767px)').matches ? $(container).find('.js-steps__item-anchor-xs') : $(container).find('.js-steps__item-anchor');
        anchors.each(function (index, item) {
            if (index < anchors.length -1) {
                let targetSvg = $($(this).data('target'));
                connectElements($(container), targetSvg.find('.js-steps__svg'), targetSvg.find('.js-steps__svg-path'), targetSvg.find('.js-steps__circle'), $(anchors[index]),  $(anchors[index + 1]));
            }
        });
    });
}

//helper functions, it turned out chrome doesn't support Math.sgn()
function signum(x) {
    return (x < 0) ? -1 : 1;
}
function absolute(x) {
    return (x < 0) ? -x : x;
}

function drawPath(svg, path, circleElement, startX, startY, endX, endY) {
    // get the path's stroke width (if one wanted to be  really precize, one could use half the stroke size)
    let stroke =  parseFloat(path.attr("stroke-width"));
    // check if the svg is big enough to draw the path, if not, set heigh/width
    if (svg.attr("height") <  endY)                 svg.attr("height", endY);
    if (svg.attr("width" ) < (startX + stroke) )    svg.attr("width", (startX + stroke + 60));
    if (svg.attr("width" ) < (endX   + stroke) )    svg.attr("width", (endX   + stroke) + 60);

    let deltaX = (endX - startX) * 0.4;
    let deltaY = (endY - startY) * 0.4;
    // for further calculations which ever is the shortest distance
    let delta  =  deltaY < absolute(deltaX) ? deltaY : absolute(deltaX);


    // draw tha pipe-like path
    // 1. move a bit down, 2. arch,  3. move a bit to the right, 4.arch, 5. move down to the end
    path.attr("d",  "M"  + startX + " " + startY +
        " V" + (startY + delta) +
        " H" + (endX - delta*signum(deltaX)) +
        " V" + endY );


    circleElement[0].setAttribute("style", 'offset-path: path("M ' + startX + ' ' + startY + ' V' + (startY + delta) + ' H' + (endX - delta*signum(deltaX)) + ' V' + endY +'");');

    $(window).scroll(function() {
        const start = window.innerHeight / 10 * 10.5,
            end = window.innerHeight / 2;

        let scrollPercent = 1 - (path[0].getBoundingClientRect().top - end) / (start - end) * 100;

        circleElement[0].setAttribute('style', 'offset-distance: '+ scrollPercent +'%; offset-path: path("M ' + startX + ' ' + startY + ' V' + (startY + delta) + ' H' + (endX - delta*signum(deltaX)) + ' V' + endY +'");');
    });
}

function connectElements(svgContainer, svg, path, circleElement, startElem, endElem) {
    let svgTop  = svgContainer.offset().top;
    let svgLeft = svgContainer.offset().left;

    let startCoord = startElem.offset();
    let endCoord   = endElem.offset();

    let startX = matchMedia('(max-width: 767px)').matches ? startCoord.left + 0.7*startElem.outerWidth() - svgLeft : startCoord.left + 0.5*startElem.outerWidth() - svgLeft,
        startY = matchMedia('(max-width: 767px)').matches ? startCoord.top  + startElem.outerHeight() - svgTop + 30 : startCoord.top  + startElem.outerHeight() - svgTop;

    let endX = matchMedia('(max-width: 767px)').matches ? endCoord.left + 0.2*endElem.outerWidth() - svgLeft : endCoord.left + 0.5*endElem.outerWidth() - svgLeft,
        endY = matchMedia('(max-width: 767px)').matches ? endCoord.top  - svgTop + 50 : endCoord.top  - svgTop;

    if (matchMedia('(max-width: 767px)').matches && startElem.data('media-align') === 'left') {
        startX = startCoord.left + 0.2*startElem.outerWidth() - svgLeft;
        endX = endCoord.left + 0.7*endElem.outerWidth() - svgLeft;
    }

    // call function for drawing the path
    drawPath(svg, path, circleElement, startX, startY, endX, endY);
}