'use strict'
import {translate}  from "../libs/@elements/translations"

export function getLoadingContainer(overlay = true, sticky = false) {
    return '<div class="js-loading__overlay loading-overlay ">' +
        '<div class="loading-spinner" aria-label="' + translate('content.loading') + '">' +
        '<div class="loading-spinner__item loading-spinner__item--1"></div>' +
        '<div class="loading-spinner__item loading-spinner__item--2"></div>' +
        '<div class="loading-spinner__item loading-spinner__item--3"></div>' +
        '</div>' +
        '<span class="sr-only">' + translate('loading') + '</span>' +
        '</div>'
}

export function removeLoadingContainer($scope) {
    $scope.find('.js-loading__overlay').remove();
}