"use strict";

import {add} from '../libs/@elements/scroll-animations';

export function initInScope($scope) {
    add(
        $scope.find('.js-fade-in'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = window.innerHeight / 2;

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.opacity = progress;
}