'use strict'

export function initInScope($scope) {
    if($scope.find('.js-product-detail__video, .js-product-detail-slider .js-product-detail__video').length){
        if(window.matchMedia('(max-width: 767px)')){
            initMobile($scope)
        }else{
            initDesktop($scope)
        }
    }
}


function initMobile($scope) {

}

function initDesktop($scope) {

}