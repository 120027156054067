'use strict'

import {getLoadingContainer, removeLoadingContainer} from "./loading";
import fetch from "../libs/@elements/fetch";
import {showNotification} from '../libs/@elements/alert-notification';

export function initInScope($scope) {
    let pendingRequest;

    $scope.find(".js-request-cart__btn").on("click", function () {
        if (pendingRequest) {
            return;
        }

        const $container = $(this).closest(".js-request-cart__container");
        $(getLoadingContainer()).appendTo($container);

        pendingRequest = fetch($(this).attr("data-href"));

        showNotification(pendingRequest, {
            "$container": $container.find(".js-request-cart__error")
        });

        pendingRequest.finally(() => {
            removeLoadingContainer($container);
            pendingRequest = null;
        });
    });
}
