"use strict";

function updateIndicators($scope) {
    $scope.find("[data-multiple-file-download-indicator]").each(function () {
        const $indicator = $(this);
        const checked = $scope.find('[data-multiple-file-download="' + $indicator.data("multiple-file-download-indicator") + '"]:checked').length;

        if (!checked) {
            $indicator.addClass("d-none");
        } else {
            $indicator.removeClass("d-none");
        }

        $indicator.html(checked);
    });
}

function updateDownloadButton(_this) {
    if ($(_this).find('input:checked').length > 0) {
        $(_this).find('.js-multiple-file-download__selected').prop('disabled', false);
    } else {
        $(_this).find('.js-multiple-file-download__selected').prop('disabled', true);
    }
}

export function initInScope($scope) {
    let $multipleFileDownload = $scope.find('.js-multiple-file-download');

    $multipleFileDownload.each(function (index, _this) {
        $(_this).find('input').on('change', function (e) {
            updateDownloadButton(_this);
            updateIndicators($scope);
        });

        $(_this).find(".js-multiple-file-download__all").on("click", function () {
            let $checkboxes = $(_this).find('[data-multiple-file-download]');
            $checkboxes.each(function () {
                $(this).data("download-checked-state", $(this).is(":checked"));
            });

            $(_this).find(".js-multiple-file-download-select-container.show.active [data-multiple-file-download]").prop("checked", true);

            let disabled = $(_this).find(".js-multiple-file-download__selected").prop("disabled");
            $(_this).find(".js-multiple-file-download__selected").prop("disabled", false).click().prop("disabled", disabled);

            $checkboxes.each(function () {
                $(this).prop("checked", $(this).data("download-checked-state"));
                $(this).data("download-checked-state", null);
            });
        });
    });
}

