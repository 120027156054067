"use strict";

import debounce from 'debounce';

export function initInScope ($scope) {

    $scope.find('.js-navbar-toggle').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);

        if($this.hasClass('is-open')){
            $this.removeClass('is-open');
            $('.navbar-collapse').removeClass('is-open');
            $('body').removeClass('open-overlay');

        }else{
            $this.addClass('is-open');
            $('.navbar-collapse').addClass('is-open');
            $('body').addClass('open-overlay');
        }
    });


    $(window).on('load', debounce(() => {
        subnavToggle($scope);
    }, 200));

    $( document ).ready(function() {
        // Store the window width
        var windowWidth = $(window).width();

        $(window).on('resize', debounce(() => {
            // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
            if ($(window).width() !== windowWidth) {
                // Update the window width for next time
                windowWidth = $(window).width();

                subnavToggle($scope);
            }
        }, 200));
    });
}

function subnavToggle($scope) {
    if (matchMedia('(max-width: 1199px)').matches) {
        $scope.find('.js-navbar-toggle__subnav').on('click', function (e) {
            e.preventDefault();

            let $thisLi = $(this).closest('li');

            if($thisLi.hasClass('is-open')){
                $thisLi.removeClass('is-open');
                $scope.find('.js-nav').removeClass('is-open');
            }else{
                $thisLi.addClass('is-open');
                $scope.find('.js-nav').addClass('is-open');
            }
        });
    }else{
        $scope.find('.js-navbar-toggle__subnav').off();
    }
}
