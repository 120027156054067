'use strict'
import {default as throwError} from "../libs/@elements/throw-error";
import {translate} from "../libs/@elements/translations";
import asyncAppend from "../libs/@elements/async-append";
import fetch from "../libs/@elements/fetch"
import {isParsleyForm, isValid} from "../libs/@elements/parsley-bootstrap-validation";
import formDataEntries from "form-data-entries";

let $modal;

export function init() {
    /**
     * checking if the modal is present
     * @type {*|jQuery|Array}
     */
    $modal = $('body').find('.js-modal--add-cart');
    if (!$modal.length) {
        throwError(translate('add.to.cart.modal.missing!'))
    }
}

export function initInScope($scope) {
    /**
     * modal check again
     */
    if ($modal || !$modal.length) {
        $modal = $('body').find('.js-modal--add-cart');
        if (!$modal.length) {
            throwError(translate('add.to.cart.modal.missing!'))
            return
        }
    }

    const
        $addCartForm = $scope.find('.js-add-cart__result'),
        url = $addCartForm.data('url'),
        $addCartBtn = $scope.find('.js-add-cart__btn');

    let pendingRequest = null;

    /**
     * add a new Cart / prevents all default submits
     * @param e
     */
    const addCart = (e) => {
        e.preventDefault();

        if (isParsleyForm($addCartForm) && !isValid($addCartForm)) {
            return;
        }

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        const payload = new URLSearchParams(formDataEntries($addCartForm[0]))

        asyncAppend({}, fetch(url, {
            body: payload
        }).then((res) => {
            return res.clone().json()
        }).then((res) => {
            if (res.success) {
                if (res.url) {
                    window.location.href = res.url;
                }
            }
        }));

    }

    /**
     * cart add triggers (enter / submit button)
     */
    $addCartBtn.on('click', addCart);
    $addCartForm.on('submit', addCart)

    $scope.find(".js-add-cart__rename").on("click", function() {
        const $button = $(this);
        const cartId = $button.data("cart-id");
        const cartName = $button.data("cart-name");

        $(".js-add-cart__rename-cart-id").val(cartId);
        $(".js-add-cart__rename-name").val(cartName);

        $(".modal--rename-cart").modal("show");
    });
}

