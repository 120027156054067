'use strict'

export function initInScope($scope) {
    const
        $scrollTop    = $scope.find('.js-scroll-top');

    if($scrollTop.length){
        $(document).scroll(function() {
            $scrollTop.toggleClass('active', $(document).scrollTop() >= 450);
        });

        $scrollTop.on('click', () => {
            $('html, body').stop().animate({
                scrollTop: 0
            }, 350, 'swing', function() {
                $('body').focus();
            })
        });
    }

}