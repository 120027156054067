'use strict'

import fetch from "../libs/@elements/fetch";

function checkPaymentProcess(url) {
    fetch(url)
        .then(response => response.json())
        .then((response) => {
            if (response.redirectUrl) {
                window.location.href = response.redirectUrl;
            } else {
                setTimeout(() => checkPaymentProcess(url), 5000);
            }
        })
        .catch((exception) => {
            console.error("something went wrong", exception);
        });
}

export function initInScope() {
    checkPaymentProcess(_config.waitForPaymentUrl);
}