"use strict";
import noUiSlider from "../libs/custom/nouislider.js";
export function initInScope($scope) {
    let slider = document.querySelector('#slider-range');
    if (slider) {
        noUiSlider.create(slider, {
            start: [parseInt(slider.dataset.priceMinCurrent), parseInt(slider.dataset.priceMaxCurrent)],
            connect: true,
            step: 1,
            format: {
                to: (v) => parseFloat(v).toFixed(0),
                from: (v) => parseFloat(v).toFixed(0)
            },
            range: {
                'min': parseInt(slider.dataset.priceMin),
                'max': parseInt(slider.dataset.priceMax)
            }
        });

        slider.noUiSlider.on('update', function (values, handle) {
            let value = values[handle];
            if (handle) {
                document.querySelector('.js-cutout-display-max').innerHTML = value;
                document.querySelector('.js-cutout-max-hidden').setAttribute('data-value', value);
            } else {
                document.querySelector('.js-cutout-display-min').innerHTML = value;
                document.querySelector('.js-cutout-min-hidden').setAttribute('data-value', value);
            }
        });
    }

}