"use strict";

export function initInScope($scope) {

    $scope.find('.js-overlay-toggle').on('click', function (evt) {
        evt.preventDefault();

        let $this = $(this);
        let $target = $($this.data('target'));

        if(!$target.hasClass('is-open')) {
            $target.addClass('is-open');
           /* $('body').addClass('open-overlay');*/
        } else {
            $target.removeClass('is-open');
            /*$('body').removeClass('open-overlay');*/
        }
    });

}

