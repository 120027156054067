'use strict';

import loadScript from "../libs/@elements/load-script";

export function initInScope($scope) {

    /*
    This code is copied from deuter implementation.
     */

    const apiKey = _config.reCaptchaKey;

    if (!apiKey) {
        return $.Deferred().reject(new Error('reCaptcha key is not set. Please set _config.reCaptchaKey'));
    }

    loadScript('https://www.google.com/recaptcha/api.js?render=' + apiKey).done(function () {
        grecaptcha.ready(function () {

            const recaptchaAction = document.getElementById('g-recaptcha-action');

            // do request for recaptcha token
            // response is promise with passed token
            grecaptcha.execute(apiKey, {action: recaptchaAction.value})
                .then( (token) => {

                    // add token value to form
                    document.getElementById('g-recaptcha-response').value = token;

                }).catch(e => {
                console.log(e)
            });

        });
    }).catch(e => {
        console.log(e);
    });

}