'use strict'

function updateInputs($repeater, $row, rows, isNew) {
    $row.find("input, select").each(function () {
        let $input = $(this);
        let regex = new RegExp($repeater.data("repeater-search"));
        let replace = $repeater.data("repeater-replace").replace("_val_", rows);

        $input.attr("name", $(this).attr("name").replace(regex, replace));

        if (isNew && $repeater.data("repeater-disable-copy-value")) {
            $input.val("");
            $input.removeClass('has-value');
            $input.closest('.form-group').removeClass('has-value');
        }
    });
}

function updateRemoveTriggers($repeater, disable) {
    $repeater.find(".js-repeater-remove").attr("disabled", disable);
}

export function initInScope($scope) {
    $scope.find(".js-repeater").each(function () {
        let $repeater = $(this);
        let $container = $repeater.find(".js-repeater-container");
        let rows = $repeater.find(".js-repeater-row").length;

        $repeater.find(".js-repeater-trigger").on("click", function () {
            if (rows >= $repeater.data("repeater-limit")) {
                $repeater.find(".js-repeater-trigger").attr("disabled", true);
                return false;
            }

            let $row = $repeater.find(".js-repeater-row").last().clone();

            updateInputs($repeater, $row, rows, true);

            $container.append($row);
            app.initModules($row);

            rows = $repeater.find(".js-repeater-row").length;

            if (rows > $repeater.data("repeater-min")) {
                updateRemoveTriggers($repeater, false);
            }
        });

        $repeater.on("click", ".js-repeater-remove", function () {
            let $row = $(this).closest(".js-repeater-row");

            if (rows <= $repeater.data("repeater-min")) {
                return false;
            }

            $row.remove();

            $repeater.find(".js-repeater-row").each(function(index) {
                updateInputs($repeater, $(this), index, false)
            });

            rows = $repeater.find(".js-repeater-row").length;

            if (rows <= $repeater.data("repeater-min")) {
                updateRemoveTriggers($repeater, true);
            }
        });

        // disable remove triggers if necessary
        if (rows <= $repeater.data("repeater-min")) {
            updateRemoveTriggers($repeater, true);
        }
    });
}