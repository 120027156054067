"use strict";

import $ from 'jquery'
import 'slick-carousel';
import {loadImg} from "../libs/@elements/lazy-img";
import {onEnterViewPort} from "../libs/@elements/viewport-utils";


export function initInScope($scope) {
    let $slider = $scope.find('.js-circle-img-teaser-slider');

    $slider.each(function () {
        let $slider = $(this);

        let sliderOptions = {
            nextArrow: '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
            prevArrow: '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
            infinite: false,
            slidesToShow: 7,
            slidesToScroll: 7,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3.4,
                        slidesToScroll: 3,
                    }
                }
            ]
        };

        initSlider($slider, sliderOptions);
    });

}

function getUrlVars()
{
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

function initSlider($slider, sliderOptions) {
    $slider.on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    }).on('init', function( slick){
        let index = getUrlVars()["sliderindex"];
        setTimeout(function(){
            $slider.slick('slickGoTo', index);

            if(index !== 'undefined'){
                $(".slick-slide[data-slick-index=" + index+ "]").addClass('active');
            }

        }, 500);

    }).slick(sliderOptions);

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}
