"use strict";
import $ from 'jquery';
import 'slick-carousel';
import { loadImg } from "../libs/@elements/lazy-img";
import { onEnterViewPort } from "../libs/@elements/viewport-utils";

const slidesToShow = 1;
const sliderOptions = {
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: false,
    swipe: false,
};

export function initInScope($scope) {
    let $teaserSlider = $scope.find('.js-teaser-slider');
    let $customImgSliders = $teaserSlider.find('.js-custom-img-slider');

    function handleSliderInitialization() {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            if ($teaserSlider.hasClass('slick-initialized')) {
                $teaserSlider.slick('unslick');
            }
        } else {
            if (!$teaserSlider.hasClass('slick-initialized')) {
                initSlider($teaserSlider, sliderOptions);
            }
        }

        $customImgSliders.each(function () {
            let $slider = $(this);
            if (!$slider.hasClass('slick-initialized')) {
                initSlider($slider, sliderOptions);
            }

            let startX, startY;

            $slider.on('mousedown', function (e) {
                startX = e.pageX;
                startY = e.pageY;
            });

            $slider.on('mouseup', function (e) {
                let endX = e.pageX;
                let endY = e.pageY;
                if (Math.abs(endX - startX) < 10 && Math.abs(endY - startY) < 10) {
                    $slider.slick('slickNext');
                }
            });
        });
    }

    handleSliderInitialization();

    $(window).on('resize', function () {
        handleSliderInitialization();
    });

    // Add click event to custom image slider buttons
    $scope.find('.icon-multiple-img').closest('button').on('click', function() {
        let $slider = $(this).closest('.product-teaser__img').find('.js-custom-img-slider');
        if ($slider.hasClass('slick-initialized')) {
            $slider.slick('slickNext');
        }
    });
}

function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + i);
    }
}

$('.custom-product-slider-section').each(function() {
    var $block = $(this);
    var readMoreBtn = $block.find(".read-more-btn");
    var productItems = $block.find(".product-item.hidden");
    var allProductItems = $block.find(".product-item");
    var mediaQuery = window.matchMedia("(max-width: 768px)");

    function handleScreenSizeChange(e) {
        if (e.matches) {
            allProductItems.each(function(index, item) {
                if (index >= 4) {
                    $(item).addClass("hidden");
                }
            });
        } else {
            allProductItems.removeClass("hidden");
            readMoreBtn.hide();
        }
    }

    mediaQuery.addEventListener("change", handleScreenSizeChange);
    handleScreenSizeChange(mediaQuery);

    readMoreBtn.on("click", function() {
        var $container = $block.find('.custom-product-slider-mobile');

        var initialHeight = $container.height();
        productItems.removeClass("hidden");

        var targetHeight = $container.prop('scrollHeight');
        $container.css({
            'height': initialHeight,
            'overflow': 'hidden'
        });
        $container.animate({
            'height': targetHeight
        }, 400, function() {
            $container.css({
                'height': 'auto',
                'overflow': 'visible'
            });
        });

        readMoreBtn.css('display', 'none');

        initInScope($block);

        $block.find(".js-custom-img-slider").each(function () {
            let $slider = $(this);
            if ($slider.hasClass('slick-initialized')) {
                preloadNSlide($slider.slick('getSlick'), 0, sliderOptions.slidesToShow);
            }
        });

        $block.find(".js-custom-img-slider").slick('slickSetOption', 'anyoption', 'anyvalue', true);
    });
});
