import * as wishlistbundle from '../libs/@elements/wishlist';
let myWishlist = null;

if (window['_wishlist']) {
    if(window['_wishlist'].wishlist) {
        myWishlist = wishlistbundle.createWishlist({
            ...window['_wishlist'].wishlist,
            notificationOptions: {
                $container: $('.js-wishlist__notification'),
                item: '.js-wishlist__btn',
                itemId: 'wishlist-id',
                wishlistId: 'wishlist-list',
                itemActiveClass: 'is-active'
            },
            translations: {
                add: 'wishlist.add',
                remove: 'wishlist.remove'
            }
        });
    }
}

export function init() {
    if (myWishlist) {
        myWishlist.init();
    }

    $(document).on('renderd.wishlist', (e, params) => {
        if (params.list === "wishlist-list") {
            if (params.activeIds) {
                const ids = params.activeIds.length;
                let $badge = $('.js-wishlist-cart__count');
                if (ids > 0) {
                    $badge.removeClass('is-active');
                    $badge[0].offsetWidth;
                    $badge.html(ids);
                    $badge.addClass('is-active');
                } else {
                    $badge.html(0)
                }
            }
        }
    }).on("adding.wishlist", () => {
        if (localStorage && localStorage.getItem("wishlist-popup") === "true") {
            return true;
        }

        $(".js-modal-wishlist-session").modal("show");

        if (localStorage) {
            localStorage.setItem("wishlist-popup", "true");
        }
    });
}

export function initInScope($scope) {
    if (myWishlist) {
        myWishlist.initInScope($scope);

        let $wishListContainer = $scope.find('.js-wishlist__container');
        $wishListContainer.each((_, el) => {
            const $container    = $(el),
                $button     = $container.find('.js-wishlist__remove'),
                id          = $container.data('wishlist-id');

            $button.on('click', function (e) {
                e.preventDefault();
                let _request = myWishlist.remove(id, {showUndo: true});
                _request.then(function (res) {
                    if(res.success){
                        $container.remove();
                    }
                })
            })
        })
    }
}
