
"use strict";

export function initInScope($scope) {
    //
    // var url = window.location.href;
    //
    // if (url.indexOf("#") > 0){
    //     let activeTab = url.substring(url.indexOf("#") + 1);
    //     $('.nav[role="tablist"] a[href="#'+activeTab+'"]').tab('show');
    // }
    // //
    // $("a[href*=\\#]").on('click', function (e) {
    //     let activeTab = $(this).attr('href');
    //     $('.nav[role="tablist"] a[href="' +activeTab+ '"]').tab('show');
    // });

}

