'use strict'
import {getPrefixedDataSet} from '../libs/@elements/data-set-utils';
import { initSlider } from '../libs/@elements/slider'

const selectors = {
    base: '.js-productLightbox',
    item: '.js-productLightbox__item'
};
const defaultOptions = {
    video: false,
    animateThumb: false,
    download: false,
    counter: false,
    zoom: false,
    thumbnail: false,
    showThumbByDefault: false
};
const
    arrowPrevMarkup = '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    arrowNextMarkup = '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>';


export function initInScope($scope) {
    const $lightboxContainer = $scope.find('.js-productLightbox');

    $lightboxContainer.each((_,el) => {
        initLightbox($(el));
    });
}

function initLightbox($container) {
    const $galleryItems   = $container.find(selectors.item);

    let elementOptions = {
        ...defaultOptions,
        ...getPrefixedDataSet('pdslightbox', $container)
    };

    import('lightgallery').then(function (lightGallery) {
        if($container.data("lightGallery"))
             $container.data("lightGallery").destroy(true);
        $container.lightGallery({
            ...elementOptions,
            addClass: 'js-productLightbox__lightbox productLightbox__lightbox',
            selector: selectors.item,
        });
        let $slider = [], $lightbox, $slides;
        $container.on('onAfterOpen.lg', (e) => {
            $lightbox = $('.js-productLightbox__lightbox')
            $slides = $lightbox.find('.lg-item');
        })

        $container.on('onAfterAppendSubHtml.lg', (e, i) => {
            $slider[i] = $lightbox.find('.js-productLightbox__slider');
            if($slider[i].length){
                $($slides[i]).addClass('productLightbox__slide--has-html');
                initSlider($slider[i], {
                        nextArrow: arrowNextMarkup,
                        prevArrow: arrowPrevMarkup,
                        infinite: false,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        variableWidth: false,
                        responsive: [
                            {
                                breakpoint: 1200,
                                settings: {
                                    slidesToShow: 3
                                }
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 1
                                }
                            },
                        ]
                    }
                )
            }
        })

        // $container.on('onBeforeClose.lg', (e) => {
        //     $slider.forEach((el) => {
        //         $(el).slick('unslick')
        //     })
        // })

    })
}
